/* 
react-kanban classes to override
    .react-kanban-board
    .react-kanban-card
    .react-kanban-card-skeleton
    .react-kanban-card--dragging
    .react-kanban-card__description
    .react-kanban-card__title
    .react-kanban-column
    .react-kanban-card-adder-form 
    .react-kanban-card-adder-button 
    .react-kanban-card-adder-form__title 
    .react-kanban-card-adder-form__description 
    .react-kanban-card-adder-form__button 
    .react-kanban-column-header 
    .react-kanban-column-header__button 
    .react-kanban-column-adder-button 
*/

.react-kanban-board {
    padding-bottom: 14px !important;
}

.react-kanban-board, .react-kanban-board > div {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex !important;
}


.react-kanban-card-skeleton {
    max-width: 220px;
    min-width: 220px;
}

.react-kanban-column {
    background-color: #242448;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 24px;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    margin: 0 6px;
    padding: 0;
}

.react-kanban-column > div:last-child{
    overflow-y: auto;
    flex: 1;
    padding: 14px 14px 0;
    margin-bottom: 20px;
}

.react-kanban-column > div:last-child::-webkit-scrollbar-thumb{
    background-color: #425AE0;
}
